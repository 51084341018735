import { useState } from "react";
import { Form } from "react-bootstrap";

import { ReservationSearchTypes } from "../../../types/v2/reservation";

import styles from "./index.module.css";

const SearchOptions = ({
  className,
  type,
  handleChange
}: {
  className: string,
  type: ReservationSearchTypes
  handleChange: (value: ReservationSearchTypes) => void
}) => {
  const [value, setValue] = useState<ReservationSearchTypes>(type);

  const onChange = (e: any) => {
    setValue(e.target.value);
    handleChange(e.target.value as ReservationSearchTypes);
  }

  return (
    <Form.Select
      value={value}
      onChange={onChange}
      className={`${styles.select} ${className} btn-primary`}
    >
      <option value="id">ID</option>
      <option value="name">Name</option>
      <option value="email">Email</option>
    </Form.Select>
  )
}

export default SearchOptions;