import { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast, ToastContainer } from 'react-toastify';

import { E_MODAL_SUBJECT } from '../types';
import { PUBLIC_BASE_URL } from '../../../../common/constants';
import useWindowSize from '../../../../common/hooks/useWindowSize';
import ApiService from '../../../../../services/api/v2';
import DeleteWaiverModal from '../unlinkedWaivers/deleteWaiverModal';
import DeleteModal from '../deleteModal';

import { Reservation } from '../../../../../types/v2/reservation';
import Customer from '../../../../../types/v2/customer';
import { Waiver } from '../../../../../types/v2/waiver';
import SocialMedia from '../../../../../types/v2/socialMedia';

const ExpandCustomer = ({
  reservation,
  actionCallback,
  style
}: {
  reservation: Reservation,
  actionCallback: () => void,
  style?: CSSProperties
}) => {
  const navigate = useNavigate();
  const [width] = useWindowSize();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showDeleteWaiver, setShowDeleteWaiver] = useState<boolean>(false);
  const [modalSubject, setModalSubject] = useState<string>('')
  const [actionWaiver, setActionWaiver] = useState<Waiver>();
  const [actionCustomer, setActionCustomer] = useState<Customer>();

  let columns: any[] = []

  if (width > 660) {
    columns = [
      {
        dataField: 'customer_id',
        text: 'ID',
        hidden: true
      },
      {
        dataField: 'name',
        text: 'NAME',
        headerStyle: () => {
          return { maxWidth: '200px' };
        },
        formatter: ((_cellContent: any, customer: Customer) => {
          return `${customer.firstname} ${customer.lastname}`;
        })
      },
      {
        dataField: 'email',
        text: 'EMAIL',
        headerStyle: () => {
          return { width: '250px' };
        },
      },
      {
        dataField: 'username',
        text: 'USERNAME',
        headerStyle: () => {
          return { maxWidth: '200px' };
        },
      },
      {
        dataField: 'isChild',
        text: 'TYPE',
        headerStyle: () => {
          return { width: '60px' };
        },
        formatter: ((_cellContent: any, customer: Customer) => {
          return customer.ReservationWaiverCustomerMapping.is_child ? "Minor" : "Adult"
        })
      },
      {
        dataField: 'social',
        text: 'SOCIAL',
        headerStyle: () => {
          return { minWidth: '200px' };
        },
        formatter: ((_cellContent: any, customer: Customer) => {
          return (
            <div style={{ fontSize: '11px' }}>
              {customer.social_medias.map((socialMedia: SocialMedia) => {
                return <div>{socialMedia.name}: {socialMedia.CustomerHasSocialMedia.handle}</div>
              })}
            </div>
          )
        })
      },
      {
        dataField: 'action',
        text: '',
        align: 'right',
        headerStyle: () => {
          return { width: '60px' };
        },
        formatter: ((_cellContent: any, customer: Customer) => {
          let waiver: Waiver | undefined = reservation.waivers.find((waiver) => waiver.email === customer.email);
          return (
            <DropdownButton
              id={`dropdown-customer-${customer.customer_id}-button`}
              title=""
            >
              <Dropdown.Item
                onClick={() => editCustomer(customer)}
              >
                Edit Customer
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => showWaiver(waiver)}
              >
                Show Waiver
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => deleteWaiver(waiver)}
              >
                Delete Waiver On S3
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => deleteCustomer(customer)}
              >
                Delete Customer
              </Dropdown.Item>
            </DropdownButton>
          )
        })
      }
    ]
  } else if (width !== 0) {
    columns = [
      {
        dataField: 'customer_id',
        text: 'ID',
        hidden: true
      },
      {
        dataField: 'Customers',
        text: 'CUSTOMERS',
        formatter: ((_cell: any, customer: Customer) => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{customer.firstname} {customer.lastname}</div>
                <div>{customer.email}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{customer.username}</div>
                <div>{customer.ReservationWaiverCustomerMapping.is_child ? "Minor" : "Adult"}</div>
              </div>
            </div>
          )
        })
      },
      {
        dataField: 'action',
        text: '',
        align: 'right',
        headerStyle: () => {
          return { width: '60px' };
        },
        formatter: ((_cellContent: any, customer: Customer) => {
          let waiver: Waiver | undefined = reservation.waivers.find((waiver) => waiver.email === customer.email);
          return (
            <DropdownButton
              id={`dropdown-customer-${customer.customer_id}-button`}
              title=""
            >
              <Dropdown.Item
                onClick={() => editCustomer(customer)}
              >
                Edit Customer
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => showWaiver(waiver)}
              >
                Show Waiver
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => deleteWaiver(waiver)}
              >
                Delete Waiver On S3
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => deleteCustomer(customer)}
              >
                Delete Customer
              </Dropdown.Item>
            </DropdownButton>
          )
        })
      }
    ]
  }

  const editCustomer = (customer: Customer) => {
    navigate(`/reservation/${reservation.reservation_id}/customer/${customer.customer_id}`, {
      state: {
        customer,
        isStandalone: true,
        isAdmin: true
      }
    });
  }

  const showWaiver = (waiver: Waiver | undefined) => {
    if (!waiver) return;
    setActionWaiver(waiver);
    window.open(`${PUBLIC_BASE_URL}/waiver/${waiver.uuid}`);
  }

  const deleteWaiver = (waiver: Waiver | undefined) => {
    setActionWaiver(waiver);
    setModalSubject(E_MODAL_SUBJECT.WAIVER);
    setShowDeleteWaiver(true);
  }

  const deleteCustomer = (customer: Customer) => {
    setActionCustomer(customer);
    setModalSubject(E_MODAL_SUBJECT.CUSTOMER);
    setShowDelete(true);
  }

  const deleteRecord = () => {
    if (modalSubject === E_MODAL_SUBJECT.WAIVER && actionWaiver) {
      ApiService.deleteWaiver(actionWaiver.waiver_id)
        .then(() => {
          setShowDeleteWaiver(false);
          setActionWaiver(undefined);
          actionCallback();
        }).catch((err) => {
          toast.error(`Delete Waiver - Error: ${JSON.stringify(err)}`);
        });
    } else if (modalSubject === E_MODAL_SUBJECT.CUSTOMER && actionCustomer) {
      ApiService.deleteCustomer(actionCustomer.customer_id, reservation.reservation_id)
        .then((response) => {
          if (response.status === 200) {
            setShowDelete(false);
            setActionCustomer(undefined);
            actionCallback();
          } else {
            toast.error(`Delete Customer - Error: ${JSON.stringify(response)}`);
          }
        }).catch((err) => {
          toast.error(`Delete Customer - Error: ${JSON.stringify(err)}`);
        });
    }
  }

  return (
    <div style={style}>
      {reservation.customers.length > 0 &&  
      <>
        {width > 660 &&
        <div className="subtable-header">
          Customers
        </div>
        }
        {width > 0 &&
        <BootstrapTable
          keyField='customer_id'
          data={reservation.customers}
          columns={columns}
          bordered={false}
        />
        }
      </>
      }
      {actionCustomer !== undefined &&
        <DeleteModal
          show={showDelete}
          setShow={setShowDelete}
          modalSubject={modalSubject}
          deleteRecord={deleteRecord}
        />
      }
      {actionWaiver !== undefined &&
        <DeleteWaiverModal
          show={showDeleteWaiver}
          setShow={setShowDeleteWaiver}
          title={`Delete ${modalSubject}`}
          deleteRecord={deleteRecord}
        />
      }
      <ToastContainer />
    </div>
  )
}

export default ExpandCustomer;