import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

interface BackProps {
  route?: string;
  classes?: string;
}

const Back = (props: BackProps) => {
  let navigate = useNavigate();
  return (
    <Button className={props.classes} onClick={() => props.route ? navigate(props.route) : navigate(-1)}>Back</Button>
  );
};

export default Back;