import { useEffect, useState } from "react";
import moment from "moment";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import useWindowSize from "../../../common/hooks/useWindowSize";
import TagCanvas from "../../../common/tag/canvas";
import MediumTable from "./medium";
import SmallTable from "./small";
import ExpandRow from "../row";

import { Reservation } from "../../../../types/v2/reservation";
import { SearchMeta } from "../../../common/search/search";

const ReservationTable = ({
  reservations,
  sizePerPage,
  count,
  page,
  onTableChange,
  reloadRecord,
  searchReservations,
  currentSearch,
  error
} : {
  reservations: Reservation[],
  sizePerPage: number,
  count: number,
  page: number,
  onTableChange: (_type: any, { page }: any) => void,
  reloadRecord: () => void,
  currentSearch: SearchMeta,
  searchReservations: () => void,
  error: Error
}) => {
  const [width] = useWindowSize();

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  /**
   * Tags
   */

  const [tagReservation, setTagReservation] = useState<Reservation | undefined>(undefined)
  const [showCanvas, setShowCanvas] = useState<boolean>(false);

  const momentFormat: string = currentSearch.query !== "" ? 'YYYY-MM-DD h:mma' : 'h:mma';

  const rowClasses = (row: Reservation) => {
    let classes = 'table-row ';

    if (row.is_done) {
      classes += 'done ';
    }

    if (moment(row.timeslot.slot).isSame(moment(), 'hour')) {
      classes += 'running ';
    }

    if (moment(row.timeslot.slot).isSame(moment(), 'day') && moment().diff(moment(row.timeslot.slot)) < 0) {
      classes += 'next-today ';
    }

    if (expandedRows.findIndex((index) => row.reservation_id === index) !== -1) {
      classes += 'expanded ';
    }

    return classes;
  }

  const handleOnExpand = (row: any, isExpand: any) => {
    if (isExpand) {
      setExpandedRows([...expandedRows, row.reservation_id]);
    } else {
      setExpandedRows(expandedRows.filter((x) => x !== row.reservation_id));
    }
  }

  const expandRow = {
    renderer: (row: any) => {
      return (
        <ExpandRow
          reservations={reservations}
          reservation={row}
          timeslotFormat={momentFormat}
          actionCallback={reloadRecord}
          searchCallback={searchReservations}
        />
      )
    },
    expanded: expandedRows,
    onExpand: handleOnExpand
  };
  
  useEffect(() => {
    if (tagReservation && showCanvas === false) {
      reloadRecord();
    }
  }, [showCanvas])

  return (
    <>
      {reservations && reservations.length > 0
        ?
        <>
          {width <= 600
          ?
          <SmallTable
            reservations={reservations}
            sizePerPage={sizePerPage}
            count={count}
            page={page}
            momentFormat={momentFormat}
            rowClasses={rowClasses}
            expandRow={expandRow}
            onTableChange={onTableChange}
          />
          :
          <MediumTable
            reservations={reservations}
            sizePerPage={sizePerPage}
            count={count}
            page={page}
            momentFormat={momentFormat}
            rowClasses={rowClasses}
            expandRow={expandRow}
            setTagReservation={setTagReservation}
            setShowCanvas={setShowCanvas}
            onTableChange={onTableChange}
          />
          }
          {tagReservation &&
          // Needs to be outside of the row else it triggers the expand/hide
          <TagCanvas
            reservation={tagReservation}
            show={showCanvas}
            close={() => setShowCanvas(false)}
          />
          }
        </>
        :
        <>
          {error
            ?
            <Alert variant="danger">
              <p>Something went wrong</p>
              <p>{JSON.stringify(error)}</p>
            </Alert>
            :
            <div style={{
              display: 'flex',
              justifyContent: 'left',
              gap: '11px',
              alignItems: 'center',
            }}>
              <FontAwesomeIcon icon={faCircleExclamation}/>
              <span>No Data</span>
            </div>
          }
        </>
        }
    </>
  )
}

export default ReservationTable;