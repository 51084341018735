import { Field, FormikProps } from "formik"
import { CirclePicker } from 'react-color';

const FieldColor = ({ formikProps }: { formikProps: FormikProps<any> }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px'
    }}>
      <Field component={CirclePicker}
        name="color"
        value={formikProps.values.color}
        onChangeComplete={(color: { hex: string }) => formikProps.setFieldValue('color', color.hex)}
      />
    </div>
  )
}

export default FieldColor;