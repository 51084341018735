import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import WebFont from 'webfontloader';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './components/common/constants';

import './index.css';

WebFont.load({
  google: {
    families: ['Public Sans', 'sans-serif', 'Teko']
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const domain = AUTH0_DOMAIN;
const clientId = AUTH0_CLIENT_ID;
const audience = AUTH0_AUDIENCE;
let nodes;

if (domain && clientId) {
  nodes = (
    <React.StrictMode>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={audience}
        options={{
          database: {
            allowSignUp: false
          }
        }}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
} else {
  nodes = <h3 style={{color: 'red'}}>Domain or Client Id not found</h3>;
}

root.render(nodes);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
