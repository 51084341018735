import { CSSProperties } from 'react';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import useWindowSize from '../hooks/useWindowSize';

interface IReloadProps {
  callback: () => void;
  style?: CSSProperties;
}

const ReloadButton = (props: IReloadProps) => {
  const [width] = useWindowSize();
  return (
    <Button onClick={props.callback} style={props.style}>
      <FontAwesomeIcon style={{ fontSize: '14px', margin: width > 1300 ? '0 16px 0 0' : '0' }} icon={faRedo} />{width > 1300 ? ' Reload' : ''}
    </Button>
  )
}

export default ReloadButton;