import { Reservation } from "../../../../../types/v2/reservation";
import Customer from "../../../../../types/v2/customer";

import styles from "./index.module.css";

const CustomerCell = ({
  reservation,
} : {
  reservation: Reservation,
}) => {
  let usernameCount = 0;
  
  reservation.customers.forEach((customer: Customer) => {
    usernameCount += customer.username !== null && customer.username !== undefined && customer.username !== '' ? 1 : 0;
  });

  return (
    <div className={styles.customers}>
      <div className={styles["customer-category"]}>
        <div className={styles["customer-title"]}>EXPECTED</div>
        <div>{reservation.player_count}</div>
      </div>
      <div className={styles["customer-category"]}>
        <div className={styles["customer-title"]}>LINKED</div>
        <div>{reservation.customers.length}</div>
      </div>
      <div>
        <div className={styles["customer-title"]}>CHECKED IN</div>
        <div>{usernameCount}</div>
      </div>
    </div>
  )
}

export default CustomerCell;