import { Modal, Row, Col, Button } from 'react-bootstrap';

interface IDeleteWaiverModalProps {
  show: boolean,
  setShow: (show: boolean) => void,
  title: string,
  deleteRecord: () => void
}

const DeleteWaiverModal = ({
  show,
  setShow,
  title,
  deleteRecord
}: IDeleteWaiverModalProps) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="deletionModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="deletionModal">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to delete the waiver?</span>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col sm={6}>
            <Button
              onClick={() => setShow(false)}
              variant="info"
            >
              Cancel
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              onClick={deleteRecord}
              variant="danger"
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteWaiverModal;
