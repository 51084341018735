import { Button, ButtonGroup } from "react-bootstrap";
import DPicker from "../../../../common/datepicker";
import DateSelect from "./select";

const DateControl = ({
  filterButtonText,
  filterButtonDate,
  dateOption,
  changeDateOption,
  changeDate,
  changeCalendar,
  resetReservationFilter
} : {
  filterButtonText: string;
  filterButtonDate: Date;
  dateOption?: string;
  changeDateOption?: (option: string) => void;
  changeDate: (past: boolean) => void;
  changeCalendar: (value: Date) => void;
  resetReservationFilter: () => void;
}) => {
  const disabled = dateOption === "all";

  return (
    <>
      <ButtonGroup>
        {dateOption && changeDateOption &&
        <DateSelect
          selected={dateOption}
          handleChange={changeDateOption}
        />
        }
        <Button 
          onClick={() => changeDate(true)}
          disabled={disabled}
          style={{ padding: '7px 14px', height: '34.4px' }}
        >
          {'<'}
        </Button>
        <Button
          id="reservationFilter"
          onClick={resetReservationFilter}
          disabled={disabled}
          className="text-right"
          style={{ width: '170px', height: '34.4px' }}
        >
          {filterButtonText}
        </Button>
        <DPicker
          onChange={(val: any) => {
            changeCalendar(val);
          }}
          disabled={disabled}
          value={filterButtonDate}
        />
        <Button
          onClick={() => changeDate(false)}
          disabled={disabled}
          style={{ padding: '7px 14px', height: '34.4px' }}
        >
          {'>'}
        </Button>
      </ButtonGroup>
    </>
  )
}

export default DateControl;