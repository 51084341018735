import { CSSProperties, ReactNode, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import moment from "moment";

import ApiService from "../../../../../services/api/v2";
import { PUBLIC_BASE_URL } from "../../../../common/constants";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import DeleteWaiverModal from "./deleteWaiverModal";

import { Reservation } from "../../../../../types/v2/reservation";
import { Waiver, WaiverCustomer } from "../../../../../types/v2/waiver";
import Customer from "../../../../../types/v2/customer";

const ChildrenCell = ({ children } : { children: WaiverCustomer[] }) => {
  const dates: ReactNode[] = [];
  const ages: ReactNode[] = [];

  children.forEach((child: WaiverCustomer) => {
    dates.push(<div>{moment(child.dob).format('MMM Do YYYY')}</div>);
    ages.push(<div>{moment().diff(child.dob, 'years')}yo</div>);
  })

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{children.length} children</div>
      <div>{dates}</div>
      <div>{ages}</div>
    </div>
  )
}

const UnlinkedWaivers = ({
  reservations,
  reservation,
  timeslotFormat,
  actionCallback,
  style
}: {
  reservations: Reservation[],
  reservation: Reservation,
  timeslotFormat: string,
  actionCallback: () => void,
  style?: CSSProperties
}) => {
  const [width] = useWindowSize();

  const [actionWaiver, setActionWaiver] = useState<Waiver | undefined>(undefined);
  const [usedWaivers, setUsedWaivers] = useState<Waiver[]>([]);
  const [showDeleteWaiver, setShowDeleteWaiver] = useState<boolean>(false);

  useState(() => {
    const used: Waiver[] = []
    reservation.customers.forEach((customer: Customer) => {
      const w = reservation.waivers.find((waiver: Waiver) => customer.ReservationWaiverCustomerMapping.waiver_id === waiver.waiver_id);
      if (w) {
        const multiUse = used.find((o: Waiver) => o.waiver_id === w.waiver_id);
        if (!multiUse) {
          used.push(w);
        }
      }
    });
    setUsedWaivers(used);
  })

  const moveWaiver = (waiver: Waiver, reservation: Reservation) => {
    ApiService.moveWaiver(waiver.waiver_id, reservation.reservation_id)
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success("Waiver moved");
          actionCallback();
        }
      }).catch((err: unknown) => {
        toast.error(`Move waiver - ${JSON.stringify(err)}`)
      });
  }

  const changeWaiverType = (waiver: Waiver, type: "adult" | "children") => {
    ApiService.changeWaiver(waiver.waiver_id, "type", type)
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success("Waiver moved");
          actionCallback();
        }
      }).catch((err: unknown) => {
        toast.error(`Move waiver - ${JSON.stringify(err)}`)
      });
  }

  const deleteWaiver = (id?: string | number) => {
    if (!actionWaiver && !id) return;

    ApiService.deleteWaiver(actionWaiver ? actionWaiver.waiver_id : id as number)
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success("Waiver deleted");
          actionCallback();
        }
      }).catch((err: unknown) => {
        toast.error(`Delete waiver - ${JSON.stringify(err)}`)
      });
  }

  const safeDeleteWaiver = (waiver: Waiver) => {
    const customersWithWaiver = reservation.customers.filter((customer: Customer) => 
      customer.ReservationWaiverCustomerMapping.waiver_id === waiver.waiver_id);

    if (customersWithWaiver.length > 0) {
      setActionWaiver(waiver);
      setShowDeleteWaiver(true);
    } else {
      deleteWaiver(waiver.waiver_id);
    }
  }

  let columns; 
  
  if (width > 660) {
    columns = [
      {
        dataField: 'waiver_id',
        text: 'ID',
        hidden: true
      },
      {
        dataField: 'email',
        text: 'EMAIL',
        headerStyle: () => {
          return { width: '300px' };
        },
      },
      {
        dataField: 'type',
        text: 'TYPE',
        formatter: ((_cellContent: any, waiver: Waiver) => {
          if (!waiver.blob.type) return "unknown";
          return waiver.blob.type.toString().toUpperCase();
        })
      },
      {
        dataField: 'details',
        text: 'CHILDREN',
        formatter: ((_cellContent: any, waiver: Waiver) => {
          if (!waiver.blob.children) {
            return <>-</>
          }

          return <ChildrenCell children={waiver.blob.children} />
        })
      },
      {
        dataField: 'action',
        text: '',
        align: 'right',
        headerStyle: () => {
          return { width: '60px' };
        },
        formatter: ((_cellContent: any, waiver: Waiver) => {
          const similarReservations = reservations.filter((res: Reservation) => 
            reservation.reservation_id !== res.reservation_id && reservation.email === res.email);
          return (
            <DropdownButton
              id={`dropdown-waiver-${waiver.waiver_id}-button`}
              title=""
            >
              <Dropdown.Item onClick={() => window.open(`${PUBLIC_BASE_URL}/waiver/${waiver.uuid}`, "_blank", "noreferrer")}>
                Show Waiver
              </Dropdown.Item>
              {similarReservations.map((res: Reservation) => {
                return (
                  <Dropdown.Item key={`move${waiver.waiver_id}to${res.reservation_id}`} onClick={() => moveWaiver(waiver, res)}>
                    Move Waiver To {res.reservation_id} - {moment(res.timeslot.slot).format(timeslotFormat)}
                  </Dropdown.Item>
                )
              })}
              {waiver.blob.type === "combined" &&
              <>
                <Dropdown.Item onClick={() => changeWaiverType(waiver, "adult")}>
                  Change Type to Adult
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeWaiverType(waiver, "children")}>
                  Change Type to Children
                </Dropdown.Item>
              </>
              }
              <Dropdown.Item onClick={() => safeDeleteWaiver(waiver)}>
                Delete Waiver
              </Dropdown.Item>
            </DropdownButton>
          )
        })
      }
    ]
  } else {
    columns = [
      {
        dataField: 'waiver_id',
        text: 'ID',
        hidden: true
      },
      {
        dataField: 'waivers',
        text: 'WAIVERS',
        formatter: ((_cellContent: any, waiver: Waiver) => {
          const type = (!waiver.blob.type) ? "unknown" : waiver.blob.type.toString().toUpperCase();
          const children = (!waiver.blob.children) ? <>-</> : <ChildrenCell children={waiver.blob.children} />;

          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{waiver.email}</div>
                <div>{type}</div>
              </div>
              <div style={{ marginTop: '7px' }}>
                {children}
              </div>
            </>
          )
        })
      },
    ]
  }
  
  const emailWaivers = () => {
    ApiService.emailWaivers(reservation.reservation_id)
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success("Email sent");
        }
      }).catch((err: Error) => toast.error(`Email waiver - ${err.message}`));
  }

  return (
    <div style={style}>
      {(reservation.waivers.length !== usedWaivers.length || reservation.waivers.length === 0) &&
      <>
        <div className="subtable-header">
          <div>
            Unlinked Waivers
          </div>
          <div>
            <DropdownButton
              id="dropdown-reservation-button"
              title="Actions"
            >
              <Dropdown.Item onClick={() => emailWaivers()}>Email Waivers</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        {reservation.waivers.length > 0 &&
        <BootstrapTable
          keyField='waiver_id'
          data={reservation.waivers}
          columns={columns}
          bordered={false}
        />
        }
        <DeleteWaiverModal
          show={showDeleteWaiver}
          setShow={setShowDeleteWaiver}
          title="This waiver is used by a customer"
          deleteRecord={deleteWaiver}
        />
      </>
      }
    </div>
  )
}

export default UnlinkedWaivers;