import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

import { Reservation } from "../../../../../types/v2/reservation";
import Tag from "../../../../../types/v2/tag";

import TagBadge from "../../../../common/tag/tag";
import TagButton from "../../../../common/tag/button";

import CustomerCell from "../customer-cell";
import Duration from "../duration";
import WaiverCell from "../waiver-cell";

const MediumTable = ({
  reservations,
  sizePerPage,
  count,
  page,
  momentFormat,
  rowClasses,
  onTableChange,
  expandRow,
  setTagReservation,
  setShowCanvas
} : {
  reservations: Reservation[],
  sizePerPage: number,
  count: number,
  page: number,
  momentFormat: string,
  rowClasses: (row: Reservation) => string,
  onTableChange: (_type: any, { page }: any) => void,
  expandRow: any,
  setTagReservation: (r: Reservation) => void,
  setShowCanvas: (b: boolean) => void
}) => {
  const columns: any[] = [
    {
      dataField: 'reservation_id',
      text: 'ID',
      headerStyle: () => {
        return { width: '50px' };
      },
      formatter: ((cellContent: any, reservation: Reservation) => {
        return <>
          {cellContent}
          {reservation.is_fraudulent &&
            <FontAwesomeIcon icon={faWarning} style={{ color: 'rgb(255,193,7)', marginLeft: '7px' }} />
          }
        </>
      })
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: () => {
        return { width: '120px' };
      },
    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'waivers',
      text: 'Waivers',
      headerStyle: () => {
        return { width: '150px' };
      },
      formatter: ((_cell: any, reservation: Reservation) => <WaiverCell reservation={reservation}/>)
    },
    {
      dataField: 'customers',
      text: 'Customers',
      headerStyle: () => {
        return { width: '280px' };
      },
      formatter: ((_cell: any, reservation: Reservation) => <CustomerCell reservation={reservation}/>)
    },
    {
      dataField: 'timeslot.slot',
      text: 'Start At',
      headerStyle: () => {
        return { width: '100px' };
      },
      formatter: ((cell: string) => moment(cell).format(momentFormat))
    },
    {
      dataField: 'duration',
      text: 'Length',
      headerStyle: () => {
        return { width: '100px' };
      },
      formatter: ((cell: number) => <Duration duration={cell} />)
    },
    {
      dataField: 'tags',
      text: 'Tags',
      headerStyle: () => {
        return { width: '200px' };
      },
      formatter: ((_cell: any, reservation: Reservation) => {
        return (
          <div style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
            {reservation.tags.map((tag: Tag) => {
              return <TagBadge key={`reservationTag${tag.tag_id}`} tag={tag} />
            })}
            </div>
            <TagButton
              reservation={reservation}
              setReservation={setTagReservation}
              setShowCanvas={() => setShowCanvas(true)}
            />
          </div>
        )
      })
    }
  ]

  return (
    <BootstrapTable
      remote
      keyField='reservation_id'
      data={reservations}
      columns={columns}
      pagination={paginationFactory({
        sizePerPage: sizePerPage,
        hidePageListOnlyOnePage: true,
        withFirstAndLast: true,
        hideSizePerPage: true,
        totalSize: count,
        showTotal: count > sizePerPage,
        page: page
      })}
      headerClasses="table-header"
      rowClasses={rowClasses}
      bordered={false}
      onTableChange={onTableChange}
      expandRow={expandRow}
    />
  )
}

export default MediumTable;