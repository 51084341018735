import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { HeatMapGrid } from "react-grid-heatmap";
import moment from "moment";

import ApiService from "../../../services/api/v2";
import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";

interface Data {
  player_count: number,
  duration: number,
  slot: string
}

interface Statistic {
  slot: string,
  data: number[]
}

interface Statistics {
  [day:string]: Statistic[]
}

const CustomerSlot = () => {
  const [stats, setStats] = useState<Statistics>({});
  const [data, setData] = useState<any>([[]]);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    ApiService.getCustomerSlot()
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((datas) => {
        setIsLoading(false);

        const heatmapStats: Statistics = {};

        const indexes: {
          [day:string]: {
            [key:string]: number 
          }
        } = {};

        datas.forEach((d: Data) => {
          const day = moment(d.slot).format('ddd');
          const slot = moment(d.slot).format('ha');

          if (indexes[day] !== undefined && indexes[day][slot] !== undefined) {
            const s = heatmapStats[day][indexes[day][slot]];
            s.data.push(d.player_count * d.duration);
            heatmapStats[day][indexes[day][slot]] = s;
          } else if (indexes[day] !== undefined && indexes[day][slot] === undefined) {
            indexes[day][slot] = heatmapStats[day].length;
            heatmapStats[day].push({ slot, data: [d.player_count * d.duration] })
          } else {
            heatmapStats[day] = [{ slot, data: [d.player_count * d.duration] }];
            indexes[day] = { [slot]: 0 };
          }
        });

        setStats(heatmapStats);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
    });
  }, [setStats, setIsLoading, setError]);

  useEffect(() => {
    let c = 0;

    const getArrayValues = (array: Statistic[]): number[] => {
      return array
        .sort((a: Statistic, b: Statistic) => moment(a.slot, "h:mm a").unix() - moment(b.slot, "h:mm a").unix())
        .map((a: Statistic) => {
          c += a.data.reduce((psum, b) => psum + b, 0);
          return a.data.reduce((b, c) => b + c) / a.data.length;
        });
    }

    if (Object.keys(stats).length > 0) {
      const mon = getArrayValues(stats["Mon"]);
      setData([
        mon,
        getArrayValues(stats["Tue"]),
        getArrayValues(stats["Wed"]),
        getArrayValues(stats["Thu"]),
        getArrayValues(stats["Fri"]),
        getArrayValues(stats["Sat"]),
        getArrayValues(stats["Sun"]),
      ]);
      setCount(c);
    }
  }, [stats, setData, setCount]);

  const render = (x: any, y: any, value: number) => {
    return value.toFixed(2);
  }

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {Object.keys(stats).length > 0 && data[0].length > 0 &&
        <Stat
          title={`Customer heatmap (${count})`}
          dataStyle={{ height: '100%'}}
          heatmap={true}
        >
          <HeatMapGrid
            data={data}
            xLabels={stats["Mon"].sort((a: any, b: any) => moment(a.slot, "h:mm a").unix() - moment(b.slot, "h:mm a").unix()).map((a: any) => a.slot)}
            xLabelsPos="bottom"
            yLabels={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
            cellHeight="2rem"
            cellRender={render}
            cellStyle={(_x, _y, ratio) => ({
              background: `rgb(105, 135, 201, ${ratio})`,
              fontSize: '.8rem',
              color: `rgb(255, 255, 255, ${ratio / 2 + 0.4})`,
              borderWidth: '0.5px',
              borderColor: 'inherit',
              borderRadius: '0'
              // margin: '1px 2px'
            })}
            xLabelsStyle={() => ({
              color: '#777',
              fontSize: '.75rem'
            })}
            yLabelsStyle={() => ({
              fontSize: '.75rem',
              color: '#777'
            })}
          />
        </Stat>
        }
      </Loader>
    </>
  )
}

export default CustomerSlot;