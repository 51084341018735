import GridLayout from 'react-grid-layout';

import DailyGameReport from "./dailyGameReport";
import NumberOfGames from "./numberOfGames";
import TimeslotByHour from './timesStats/timeslotByHour';
import Heatmap from './timesStats/heatmap';
import CustomerAverage from './customerAverage';
import useWindowSize from '../common/hooks/useWindowSize';
import CustomerSlot from './customerSlot';
import Reviews from './reviews';
import MonthlyCustomerReservationCount from './monthlyCustomerCount';
import RoomK from './room-k';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const Stats = () => {
  const [width] = useWindowSize();

  const layout = [
    // First row
    { i: "mcr", x:0, y: 0, w:4, h:2, static: true}, // MonthlyCustomerReservationCount
    { i: "tbh", x: 4, y: 0, w: 3, h: 2, static: true }, // TimeslotByHour
    // Second row
    { i: "hm", x: 0, y: 2, w: 3, h: 2, static: true }, // Heatmap
    { i: "dgr", x: 3, y: 2, w: 1, h: 1, static: true }, // DailyGameReport
    { i: "ca", x: 3, y: 3, w: 1, h: 1, static: true }, // CustomerAverage
    { i: "cs", x: 4, y: 2, w: 3, h: 2, static: true }, // CustomerSlot
    // Third row
    { i: "rw", x: 0, y: 4, w:2, h: 2, static: true }, // Reviews
    { i: "nog", x: 2, y: 4, w: 2, h: 2, static: true }, // NumberOfGames
    { i: "ho", x: 4, y: 4, w:2, h: 3, static: true } // RoomK
  ];

  return (
    <div style={{ display: 'flex', gap: '20px', margin: '30px 0' }}>
      <GridLayout
        layout={layout}
        cols={7}
        rowHeight={200}
        width={width - 80}
      >
        <div key="mcr"><MonthlyCustomerReservationCount/></div>
        <div key="tbh"><TimeslotByHour /></div>
        <div key="nog"><NumberOfGames /></div>
        <div key="ca"><CustomerAverage /></div>
        <div key="hm"><Heatmap /></div>
        <div key="dgr"><DailyGameReport /></div>
        <div key="cs"><CustomerSlot /></div>
        <div key="rw"><Reviews/></div>
        <div key="ho"><RoomK/></div>
      </GridLayout>
    </div>
  )
}

export default Stats;