import { Reservation } from "../../../../../types/v2/reservation";
import { E_WAIVER_TYPE, Waiver } from "../../../../../types/v2/waiver.d";

import styles from "../customer-cell/index.module.css";

const WaiverCell = ({
  reservation,
} : {
  reservation: Reservation,
}) => {
  let customerCount = 0;

  reservation.waivers.forEach((waiver: Waiver) => {
    if (waiver.blob.type === E_WAIVER_TYPE.ADULT || waiver.blob.type === E_WAIVER_TYPE.COMBINED) {
      customerCount += 1;
    }

    if (waiver.blob.type === E_WAIVER_TYPE.CHILDREN || waiver.blob.type === E_WAIVER_TYPE.COMBINED) {
      customerCount += waiver.blob.children.length;
    }
  });

  return (
    <div className={styles.customers}>
      <div className={styles["customer-category"]}>
        <div className={styles["customer-title"]}>COUNT</div>
        <div>{reservation.waivers.length}</div>
      </div>
      <div>
        <div className={styles["customer-title"]}>MEMBER</div>
        <div>{customerCount}</div>
      </div>
    </div>
  )
}

export default WaiverCell;