import { CSSProperties, useState } from "react";
import { Button, Form } from "react-bootstrap";

import { PUBLIC_BASE_URL } from "../../../../common/constants";
import ApiService from "../../../../../services/api/v2";

import { Reservation } from "../../../../../types/v2/reservation";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import { toast } from "react-toastify";

const ReservationDetails = ({
  reservation,
  style,
  searchCallback
}: {
  reservation: Reservation,
  style?: CSSProperties,
  searchCallback: () => void
}) => {
  const [width] = useWindowSize();

  const [error, setError] = useState<string | undefined>(undefined);

  const toggleFraudulent = () => {
    setError(undefined);
    ApiService.postReservationFraudulent(reservation.reservation_id)
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        reservation.is_fraudulent = !reservation.is_fraudulent
        searchCallback();
      }).catch((err) => {
        setError(err.message);
      });
  }

  const showReport = () => {
    ApiService.showScores(parseInt(reservation.room), reservation.reservation_id)
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success(`Score request sent on TV ${reservation.room}`);
        }
      }).catch((err: Error) => toast.error(`Score request on TV ${reservation.room} - ${err.message}`));
  }

  const stopReport = () => {
    ApiService.resetTv(parseInt(reservation.room))
      .then((response: Response) => {
        if (response.status === 200) {
          toast.success(`Reset TV ${reservation.room} sent`);
        }
      }).catch((err: Error) => toast.error(`Reset TV ${reservation.room} - ${err.message}`));
    }

  const mdStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  }

  return (
    <div style={{
      ...style,
      ...width > 660 ? mdStyle : {},
      marginBottom: '10px'
    }}>
      <div style={{ flex: '1' }}>
        <Form.Group id="isFraudulent">
          <Form.Check
            id="fraudulent"
            className="prevent-select"
            type="checkbox"
            name="isFraudulent"
            label="Is Fraudulent"
            checked={reservation.is_fraudulent}
            onChange={toggleFraudulent}
          />
        </Form.Group>
        <span style={{ color: '#c10000' }}>{error}</span>
      </div>
      <div style={{ flex: '2' }}>
        <div>
          Waiver link
        </div>
        <a 
          className="btn btn-primary"
          href={`${PUBLIC_BASE_URL}/reservation/${reservation.uuid}/waiver/start`}
          target="_blank"
          rel="noreferrer"
        >
          {reservation.uuid}
        </a>
      </div>
      {width > 660 &&
      <div style={{ flex: '1' }}>
        <div>Phone</div>
        <a 
          className="btn btn-primary"
          href={`tel:${reservation.phone}`}
        >
          {reservation.phone}
        </a>
      </div>
      }
      <div style={{ flex: '2' }}>
        <div>
          <div>Game reports</div>
          {reservation.game_reports.length > 0
          ?
          <a className="btn btn-primary"
            href={`${PUBLIC_BASE_URL}/game-report/${reservation.uuid}`}
            target="_blank"
            rel="noreferrer"
          >
            {reservation.uuid}
          </a>
          :
          <span>Pending...</span>
          }
        </div>
      </div>
      {reservation.room &&
      <div>
        <div>TV Control</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '13px' }}>
          <Button onClick={showReport}>Show Report</Button>
          <Button onClick={stopReport}>Stop Report</Button>
        </div>
      </div>
      }
    </div>
  )
}

export default ReservationDetails;