import { Badge } from "react-bootstrap";

const Duration = ({
  duration
} : {
  duration: number
}) => {
  const variant = duration === 1
    ? 'primary'
    : duration === 2
      ? 'warning'
      : 'success';

  return (
    <Badge bg={variant}>
      {duration}h
    </Badge>
  );
}

export default Duration;