import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

import ApiService from "../../../services/api/v2";
import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";

const CustomerAverage = () => {
  const [stats, setStats] = useState<{ average: number, max: number, min: number }>({
    average: 0,
    max: 0,
    min: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    setError(undefined);

    ApiService.getCustomerAverage()
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats) => {
        setIsLoading(false);
        setStats(stats);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setStats, setIsLoading, setError]);

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {stats &&
        <Stat title="Customer Average">
          <div className="mb-2">
            <span>Average: {stats.average}</span>
          </div>
          <div>
            <span>Max: {stats.max}</span>
          </div>
          <div>
            <span>Min: {stats.min}</span>
          </div>
        </Stat>
        }
      </Loader>
    </>
  )
}

export default CustomerAverage;