const plural = (val: number) => {
  return val > 1 ? 's' : '';
}

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  let str = '';

  if (minutes) {
    str = `${minutes} minute${plural(minutes)} `;
  }
  const strSec = seconds === 0 ? minutes ? '' : '0' : `${seconds} second${plural(seconds)}`;
  return str + strSec;
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}