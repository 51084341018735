import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Reservation } from "../../../../../types/v2/reservation";

import CustomerCell from "../customer-cell";
import Duration from "../duration";
import WaiverCell from "../waiver-cell";

const SmallTable = ({
  reservations,
  sizePerPage,
  count,
  page,
  momentFormat,
  rowClasses,
  onTableChange,
  expandRow
} : {
  reservations: Reservation[],
  sizePerPage: number,
  count: number,
  page: number,
  momentFormat: string,
  rowClasses: (row: Reservation) => string,
  onTableChange: (_type: any, { page }: any) => void,
  expandRow: any
}) => {
  const columns: any[] = [
    {
      dataField: 'reservation_id',
      text: 'ID',
      hidden: true,
      headerStyle: () => {
        return { width: '50px' };
      },
      formatter: ((cellContent: any, reservation: Reservation) => {
        return <>
          {cellContent}
          {reservation.is_fraudulent &&
            <FontAwesomeIcon icon={faWarning} style={{ color: 'rgb(255,193,7)', marginLeft: '7px' }} />
          }
        </>
      })
    },
    {
      dataField: 'reservation',
      text: 'Reservations',
      formatter: ((_cell: any, reservation: Reservation) => {
        return (
          <div style={{ padding: '7px 0' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <div>{reservation.reservation_id}</div>
              <div>{reservation.name}</div>
              <div>{moment(reservation.timeslot.slot).format(momentFormat)}</div>
              <Duration duration={reservation.duration}/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '7px'}}>
              <div><a className="btn btn-primary" href={`mailto:${reservation.email}`}>{reservation.email}</a></div>
              <div><a className="btn btn-primary" href={`tel:${reservation.phone}`}>{reservation.phone}</a></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '7px', gap: '20px', fontSize: '80%' }}>
              <div>
                <span>WAIVERS</span>
                <WaiverCell reservation={reservation} />
              </div>
              <div>
                <span>CUSTOMERS</span>
                <CustomerCell reservation={reservation} />
              </div>
            </div>
          </div>
        )
      })
    }
  ]

  return (
    <BootstrapTable
      remote
      keyField='reservation_id'
      data={reservations}
      columns={columns}
      pagination={paginationFactory({
        sizePerPage: sizePerPage,
        hidePageListOnlyOnePage: true,
        withFirstAndLast: true,
        hideSizePerPage: true,
        totalSize: count,
        showTotal: count > sizePerPage,
        page: page
      })}
      headerClasses="table-header"
      rowClasses={rowClasses}
      bordered={false}
      onTableChange={onTableChange}
      expandRow={expandRow}
    />
  )
}

export default SmallTable;