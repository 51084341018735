import { ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "../common/button/login";
import { JWT } from "../common/constants";
import Loader from "../common/loader/loader";

const AuthenticationChecker = ({ children }: { children: ReactNode }) => {
  let { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const searchParams = new URLSearchParams(document.location.search)
  
  // isAuthenticated = true;
  // user = {
  //   "nickname": "operator",
  //   "name": "Operator",
  //   "picture": "https://s.gravatar.com/avatar/09f537ff9f6b6eb599d43d9dab2d7247?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fop.png",
  //   "updated_at": "2024-03-22T20:17:05.678Z",
  //   "email": "operator@tidalforcevr.com",
  //   "email_verified": false,
  //   "sub": "auth0|636e894c41b5ec8e1ae740fe"
  // }

  if (searchParams.get('code')) {
    localStorage.setItem('code', searchParams.get('code') as string);
  }

  if (searchParams.get('state')) {
    localStorage.setItem('state', searchParams.get('state') as string);
  }

  if (isLoading) {
    return <Loader
      isLoading={true}
      centered={true}
    />;
  }

  if (isAuthenticated) {
    if (JWT) {
      localStorage.setItem('authToken', JWT);
    } else {
      getAccessTokenSilently().then((authToken) => {
        localStorage.setItem('authToken', authToken);
      });
    }
  }
  
  return (
    <>
      {isAuthenticated && user
        ? (
          <>
            {children}
          </>
        ) : (
          <div style={{
            height: '100%',
            textAlign: 'center',
            backgroundColor: 'black'
          }}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <p style={{
                fontSize: '100px',
                fontFamily: 'Teko'
              }}>
                HIGGS
              </p>
              <LoginButton/>
            </div>
          </div>
        )
      }
    </>
  );
};

export default AuthenticationChecker;
