import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { HeatMapGrid } from "react-grid-heatmap";
import moment from "moment";

import Loader from "../../../common/loader/loader";
import Stat from "../../../common/stat";

import useTimestats from "..";

const Heatmap = () => {
  const {
    timeStats,
    heatmapStats,
    isLoading,
    error
  } = useTimestats();

  const [data, setData] = useState<[][]>([[]]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let c = 0;

    const getArrayValues = (array: any): [] => {
      return array
        .sort((a: any, b: any) => moment(a.slot, "h:mm a").unix() - moment(b.slot, "h:mm a").unix())
        .map((a: any) => {
          c += a.count;
          return a.count
        });
    }

    if (heatmapStats) {
      const mon = getArrayValues(heatmapStats["Mon"]);
      setData([
        mon,
        getArrayValues(heatmapStats["Tue"]),
        getArrayValues(heatmapStats["Wed"]),
        getArrayValues(heatmapStats["Thu"]),
        getArrayValues(heatmapStats["Fri"]),
        getArrayValues(heatmapStats["Sat"]),
        getArrayValues(heatmapStats["Sun"]),
      ]);
      setCount(c);
    }
  }, [heatmapStats, setData, setCount]);

  const render = (x: any, y: any, value: any) => {
    return value;
  }

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {data && heatmapStats &&
        <Stat
          title={`Reservations heatmap (${count})`}
          dataStyle={{ height: '100%'}}
          heatmap={true}
        >
          <HeatMapGrid
            data={data}
            xLabels={heatmapStats["Mon"].sort((a: any, b: any) => moment(a.slot, "h:mm a").unix() - moment(b.slot, "h:mm a").unix()).map((a: any) => a.slot)}
            xLabelsPos="bottom"
            yLabels={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
            cellHeight="2rem"
            cellRender={render}
            cellStyle={(_x, _y, ratio) => ({
              background: `rgb(105, 135, 201, ${ratio})`,
              fontSize: '.8rem',
              color: `rgb(255, 255, 255, ${ratio / 2 + 0.4})`,
              borderWidth: '0.5px',
              borderColor: 'inherit',
              borderRadius: '0'
              // margin: '1px 2px'
            })}
            xLabelsStyle={() => ({
              color: '#777',
              fontSize: '.75rem'
            })}
            yLabelsStyle={() => ({
              fontSize: '.75rem',
              color: '#777'
            })}
          />
        </Stat>
        }
      </Loader>
    </>
  )
}

export default Heatmap;