import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from "./index.module.css";

interface ILoaderProps {
  isLoading: boolean;
  children?: React.ReactNode;
  centered?: boolean;
}

const Loader = (props: ILoaderProps) => (
  <>
    {props.isLoading 
    ?
    <>
      {props.centered
      ?
      <div className={styles["wrapper"]}>
        <FontAwesomeIcon icon={faSpinner} spin/>
      </div>
      :
      <FontAwesomeIcon icon={faSpinner} spin/>
      }
    </>
    :
    <>
      {props.children}
    </>
    }
  </>
)

export default Loader