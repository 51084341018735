import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Pie } from "react-chartjs-2";

import Loader from "../../common/loader/loader";
import ApiService from "../../../services/api/v2";
import Stat from "../../common/stat";

ChartJS.register([Tooltip, ArcElement, Legend]);

const NumberOfGames = () => {
  const [stats, setStats] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);

    ApiService.getGameReportStats()
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats) => {
        setIsLoading(false);
        setStats(stats);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setStats, setIsLoading, setError]);

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {stats &&
        <Stat title={`Game reports (${stats.total})`}>
          <Pie
            data={{
              labels: ['Tidal Force', 'Space Defense', 'Space Versus', 'Room K'],
              datasets: [
                {
                  label: '# of games played',
                  data: [stats.tf, stats.sd, stats.sv, stats.ho],
                  backgroundColor: [
                    '#6987C9',
                    '#88B380',
                    '#DEF2C8',
                    '#b38080'
                  ]
                }
              ]
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#FFFFFF'
                  }
                }
              },
              borderColor: '#3F4045',
            }}
          />
        </Stat>
        }
      </Loader>
    </>
  )
}

export default NumberOfGames;