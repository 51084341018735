import React, { LegacyRef } from "react";
// import React, { LegacyRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "react-bootstrap";

import styles from './index.module.css';

const Profile = () => {
  // DEBUG
  const { logout } = useAuth0();
  const user = {
    "nickname": "operator",
    "name": "Operator",
    "picture": "https://s.gravatar.com/avatar/09f537ff9f6b6eb599d43d9dab2d7247?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fop.png",
    "updated_at": "2024-03-22T20:17:05.678Z",
    "email": "operator@tidalforcevr.com",
    "email_verified": false,
    "sub": "auth0|636e894c41b5ec8e1ae740fe"
  }


  // PROD
  // const { user, logout } = useAuth0();
  // const { user, logout, getAccessTokenSilently } = useAuth0();

  // useEffect(() => {
  //   getAccessTokenSilently()
  //     .then((response) => {
  //       console.log(response);
  //     }).catch((err) => console.log(err));
  // }, [getAccessTokenSilently])
  if (!user) {
    return (
      <>
        Error: User information not readable.
      </>
    );
  }

  const CustomToggle = React.forwardRef(({ onClick }: { onClick: (e: React.MouseEvent) => void }, ref: LegacyRef<HTMLDivElement> | undefined) => (
    <div
      ref={ref}
      id={styles['dropdown-toggle']}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className={styles.name}>
        <span
          style={{
            fontWeight: 'bold',
            paddingRight: '1rem'
          }}
        >
          {user.name}
        </span>
      </div>
      <div>
        <img 
          src={user.picture}
          alt={user.name}
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '24px'
          }}
        />
      </div>
    </div>
  ));

  const logoutClick = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('code');
    localStorage.removeItem('state');
    logout({ returnTo: window.location.origin })
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}/>
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          disabled
        >
          {user.email}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={logoutClick}
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Profile;
