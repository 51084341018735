import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment-timezone";

import { RESERVATION, WS_URL } from "../../common/constants";
import Loader from "../../common/loader/loader";
import { defaultSearchValues, SearchMeta } from "../../common/search/search";
import ApiService from "../../../services/api/v2";
import TableControls from "./table-controls";
import ReservationTable from "./reservation-table";

import { Reservation } from "../../../types/v2/reservation";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.css';

const Dashboard = () => {
  const endRef = useRef<null | HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [count, setCount] = useState<number>(0);
  const [error, setError] = useState<any | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [totalCustomerNumbers, setTotalCustomerNumbers] = useState<number>(0);
  const [totalTicketNumbers, setTotalTicketNumbers] = useState<number>(0);
  const [filterButtonDate, setFilterButtonDate] = useState<Date>(new Date());
  const [currentSearch, setCurrentSearch] = useState<SearchMeta>(localStorage.getItem('currentSearch') 
    ? JSON.parse(localStorage.getItem('currentSearch') as string) as SearchMeta
    : defaultSearchValues
  ); // Look for an existing search in the local storage

  const sizePerPage: number = 39;

  const scrollToBottom = () => {
    endRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const searchReservations = () => {
    if (!currentSearch.query || currentSearch.query === "") return;

    setIsLoading(true);
    setError(undefined);

    const filters = {
      range: RESERVATION.ALL,
      [currentSearch.type as string]: currentSearch.query
    };

    ApiService.getReservations(filters, sizePerPage, page)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((data) => {
        const _res: Reservation[] = data.rows;
        setReservations(_res);
        setTotalCustomerNumbers(_res.map((reservation: Reservation) => reservation.customers.length).reduce((partialSum: any, a: any) => partialSum + a, 0));
        setTotalTicketNumbers(_res.map((reservation: Reservation) => reservation.player_count * reservation.duration).reduce((partialSum: any, a: any) => partialSum + a, 0));
        setCount(data.count);
        setIsLoading(false);

        if (moment().isAfter(moment('19:00:00', 'hh:mm:ss'))) {
          scrollToBottom();
        }
      }).catch(async (error) => {
        setError(error);
      });
  }

  const getReservations = (date?: Date) => {
    setIsLoading(true);
    setError(undefined);

    const d = moment(date || filterButtonDate);

    const filters = {
      range: RESERVATION.DAY,
      day: d.date(),
      month: d.month() + 1,
      year: d.year()
    };

    const abortController = new AbortController();
    ApiService.getReservations(filters, sizePerPage, page, abortController.signal)
      .then(async (response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
      }).then((data) => {
        const _res: Reservation[] = data.rows;
        setReservations(_res);
        setTotalCustomerNumbers(_res.map((reservation: Reservation) => reservation.customers.length).reduce((partialSum: any, a: any) => partialSum + a, 0));
        setTotalTicketNumbers(_res.map((reservation: Reservation) => reservation.player_count * reservation.duration).reduce((partialSum: any, a: any) => partialSum + a, 0));
        setCount(data.count);
        setIsLoading(false);

        if (moment().isAfter(moment('19:00:00', 'hh:mm:ss'))) {
          scrollToBottom();
        }
      }).catch(async (error) => {
        setError(error);
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };    
  }

  const reloadRecord = (date?: Date) => {
    if (currentSearch.active && currentSearch.query !== "") {
      searchReservations();
    } else {
      getReservations(date);
    }
  }

  const onTableChange = (_type: any, { page }: any) => {
    setPage(page);
  }

  useEffect(() => {
    reloadRecord();
  }, [currentSearch.query, currentSearch.type, page, filterButtonDate]);

  /**
   * WebSockets
   */

  const { lastMessage } = useWebSocket(WS_URL);

  useEffect(() => {
    if (lastMessage !== null) {
      if (currentSearch.active && currentSearch.query !== "") return;

      // on receiving a message, add it to the list of messages
      const split = lastMessage.data.split("_");
      let date = undefined;
      if (split.length > 1) {
        date = moment(split[split.length - 1]);
      }

      if ((lastMessage.data.includes('reservation') || lastMessage.data.includes('waiver') || lastMessage.data === 'customers') && (!date || date.isSame(new Date(), "day"))) {
        getReservations();
      }
    }
  }, [lastMessage]);

  return (
    <div className={styles.dashboard}>
      <TableControls
        title={
          <>
            <h1>Reservation</h1>
            <div>Tickets: {totalTicketNumbers}</div>
            <div>Customers: {totalCustomerNumbers}</div>
          </>
        }
        reload={reloadRecord}
        filterButtonDate={filterButtonDate}
        setFilterButtonDate={setFilterButtonDate}
        setCurrentSearch={setCurrentSearch}
        setPage={setPage}
        setCount={setCount}
      />
      <Loader
        isLoading={isLoading}
        centered={true}
      >
        <ReservationTable
          reservations={reservations}
          page={page}
          sizePerPage={sizePerPage}
          count={count}
          onTableChange={onTableChange}
          reloadRecord={reloadRecord}
          currentSearch={currentSearch}
          searchReservations={searchReservations}
          error={error}
        />
      </Loader>
      <div ref={endRef} />
    </div>
  )
}

export default Dashboard;