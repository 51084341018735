
import { ROOT_URL } from "../../../components/common/constants";
import Customer from "../../../types/v2/customer";
import { ReservationFilters } from "../../../types/v2/reservation";
import Tag from "../../../types/v2/tag";

export default class ApiService {
  public static apiBase = '/v2';

  private static async sendRequest(method: string, path: string, body = '{}', signal?: AbortSignal) {
    const configInit: RequestInit = {
      method,
      credentials: 'include',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      cache: 'default',
      signal
    };

    if (method !== 'GET') {
      configInit.body = body;
    }

    return fetch(
      `${ROOT_URL}${path}`,
      configInit
    );
  };

  public static emailWaivers = (id: number) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/email/waiver?reservation=${id}`
    )
  }

  /**
   * Reservation
  **/

  public static getReservations = (
    filters: ReservationFilters,
    sizePerPage: number,
    page: number,
    signal?: AbortSignal
  ) => {
    let params = `?sizePerPage=${sizePerPage}&page=${page}`;

    for (const key in filters) {
      params += `&${key}=${filters[key as keyof ReservationFilters]}`
    }

    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/reservation${params}`,
      '{}',
      signal
    );
  };

  public static postReservationFraudulent = (
    id: number
  ) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/reservation/${id}/fraudulent`,
      '{}',
    );
  }

  /**
   * Customer
  **/

  public static getCustomer = (id: string) => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/customer/${id}`
    );
  };

  public static putCustomer = (customer: Customer) => {
    return ApiService.sendRequest(
      'PUT',
      `${ApiService.apiBase}/customer/${customer.customer_id}`,
      JSON.stringify(customer)
    );
  };

  public static deleteCustomer = (cid: number, rid: number) => {
    return ApiService.sendRequest(
      'DELETE',
      `${ApiService.apiBase}/customer/${cid}`,
      JSON.stringify({ reservationId: rid })
    );
  }

  /**
   * Waiver
  **/

  public static moveWaiver = (wid: number, rid: number) => {
    return ApiService.sendRequest(
      'PUT',
      `${ApiService.apiBase}/waiver/${wid}`,
      JSON.stringify({ reservation_id: rid })
    )
  }

  public static changeWaiver = (id: number, attribute: string, value: string) => {
    return ApiService.sendRequest(
      'PUT',
      `${ApiService.apiBase}/waiver/${id}?${attribute}=${value}`,
      '{}'
    )
  }

  public static deleteWaiver = (id: number) => {
    return ApiService.sendRequest(
      'DELETE',
      `${ApiService.apiBase}/waiver/${id}`
    );
  }

  /**
   * Experience
  **/

  public static getExperiences = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/experience`
    );
  };

  /**
   * Game Report
  **/

  public static getGameReports = (
    filters: ReservationFilters,
    sizePerPage: number,
    page: number,
    signal?: AbortSignal
  ) => {
    let params = `?sizePerPage=${sizePerPage}&page=${page}`;

    for (const key in filters) {
      params += `&${key}=${filters[key as keyof ReservationFilters]}`
    }

    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/gamereport${params}`,
      '{}',
      signal
    );
  };

  /**
   * TV App
  **/

  public static showScores = (tv: number, reservation: number) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/tv-app/${tv}/reservation/${reservation}/show-scores`
    );
  };

  public static showMessage = (
    tv: number,
    name: string,
    message: string,
    length: number
  ) => {
    const body = {
      name,
      message,
      length
    };

    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/tv-app/${tv}/message`,
      JSON.stringify(body)
    )
  }

  public static resetTv = (tv: number) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/tv-app/${tv}/reset`
    );
  };

  /**
   * Stats
  **/

  public static getGameReportDayStats = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/game-report/day-report`
    );
  };

  public static getGameReportStats = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/game-report/`
    );
  };

  public static getTimeslotHour = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/timeslot-hour/`
    );
  };

  public static getCustomerAverage = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/customer-average/`
    );
  };

  public static getCustomerSlot = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/customer-slot/`
    );
  };

  public static getMonthlyCustomerReservationStats = (years?: string[]) => {
    let selection = '';
    years?.forEach((year: string, index: number) => selection += `${index === 0 ? "?year=" : "&year="}${year}`);
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/monthly-customer-reservation${selection}`
    );
  };

  public static getRoomKStats = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/stats/room-k/`
    );
  }

  /**
   * Meta
   */

  public static getTags = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/meta/tags/`
    );
  }

  public static postTag = (tag: Tag) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/meta/tag/`,
      JSON.stringify(tag)
    );
  }

  public static addTagToReservation = (tagId: number, reservationId: number) => {
    return ApiService.sendRequest(
      'POST',
      `${ApiService.apiBase}/meta/tag/${tagId}`,
      JSON.stringify({ reservationId })
    );
  }

  public static removeTagWithDelete = (tagId: number, reservationId: number) => {
    return ApiService.sendRequest(
      'DELETE',
      `${ApiService.apiBase}/meta/tag/${tagId}`,
      JSON.stringify({ reservationId })
    );
  }

  public static getMonthData = (date: string) => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/meta/month/${date}`
    );
  }

  /**
   * Google
   */

  public static getGoogleAccounts = () => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/google/accounts`
    );
  }

  public static getGoogleLocations = (aid: string) => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/google/accounts/${aid}/locations`
    );
  }

  public static getGoogleReviews = (aid: string, lid: string) => {
    return ApiService.sendRequest(
      'GET',
      `${ApiService.apiBase}/google/accounts/${aid}/locations/${lid}/reviews`
    );
  }
}