import { DOTS, usePagination } from '../hooks/usePagination';

import styles from './index.module.css';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className
} : {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className: string;
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (!paginationRange || currentPage === 0 || paginationRange.length < 2) return <></>;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`${styles['pagination-container']} ${className}`}>
      <li
        className={`${styles['pagination-item']} ${currentPage === 1 ? styles.disabled: ''}`}
        onClick={onPrevious}
      >
        <div className={`${styles.arrow} ${styles.left}`} />
      </li>
      {paginationRange.map((pageNumber: any, index: number) => {
        if (pageNumber === DOTS) {
          return <li key={`page${index}`} className={`${styles["pagination-item"]} ${styles.dots}`}>&#8230;</li>;
        }

        return (
          <li
            className={`${styles["pagination-item"]} ${pageNumber === currentPage ? styles.selected : ''}`}
            onClick={() => onPageChange(pageNumber)}
            key={`page${index}`}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`${styles["pagination-item"]} ${currentPage === lastPage ? styles.disabled : ''}`}
        onClick={onNext}
      >
        <div className={`${styles.arrow} ${styles.right}`} />
      </li>
    </ul>
  );
};

export default Pagination;
