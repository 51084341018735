import { Reservation } from '../../../../types/v2/reservation';

export interface IExpandRow {
  reservations: Reservation[];
  reservation: Reservation;
  timeslotFormat: string;
  actionCallback: () => void;
  searchCallback: () => void;
}

export const E_MODAL_SUBJECT = Object.freeze({
  WAIVER: "Waiver",
  CUSTOMER: "Customer"
});