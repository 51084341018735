import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import ExpandCustomer from './customers';
import ReservationDetails from './reservation';
import UnlinkedWaivers from './unlinkedWaivers';

import { IExpandRow } from './types';
import { Reservation } from '../../../../types/v2/reservation';

const ExpandRow = (props: IExpandRow) => {
  const [reservation, setReservation] = useState<Reservation>(props.reservation);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isLoading) {
      const res = {...reservation};
      for (const waiver of res.waivers) {
        if (waiver.blob.customers) {
          waiver.blob = waiver.blob.customers;
        }
      }

      setReservation(res);
      setIsLoading(false);
    }
  }, [reservation, isLoading, setReservation, setIsLoading]);

  return (
    <>
      <ReservationDetails
        reservation={reservation}
        searchCallback={props.searchCallback}
      />
      <div style={{
        padding: '12px 0',
        borderTop: '1px solid #3e3e3e'
      }}>
        <UnlinkedWaivers
          reservations={props.reservations}
          reservation={reservation}
          timeslotFormat={props.timeslotFormat}
          actionCallback={props.actionCallback}
        />
        <ExpandCustomer
          reservation={reservation}
          actionCallback={props.actionCallback}
        />
      </div>
      <ToastContainer/>
    </>
  )
}

export default ExpandRow;