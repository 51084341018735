import { CSSProperties, ReactNode } from "react"

import styles from "./index.module.css";

const Stat = ({
  title,
  dataStyle,
  heatmap,
  children
}: {
  title: string | ReactNode;
  dataStyle?: CSSProperties;
  heatmap?: boolean;
  children: ReactNode;
}) => {
  return (
    <div className={styles.stats}>
      <div className={`${styles.stat} ${heatmap ? styles.heatmap : ''}`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.data} style={dataStyle}>
          {children}
        </div>
      </div>
    </div>
  );
}

Stat.defaultProps = {
  heatmap: false
}

export default Stat;