import { Modal, Row, Col, Button } from 'react-bootstrap';

interface IDeleteModalProps {
  show: boolean,
  setShow: (show: boolean) => void,
  modalSubject: string,
  deleteRecord: () => void
}

const DeleteModal = ({
  show,
  setShow,
  modalSubject,
  deleteRecord
}: IDeleteModalProps) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="deletionModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="deletionModal">
          Delete {modalSubject}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the {modalSubject?.toLowerCase()}?
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col sm={6}>
            <Button
              onClick={() => setShow(false)}
              variant="info"
            >
              Cancel
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              onClick={deleteRecord}
              variant="danger"
              autoFocus
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal;
