import { ReactNode, useRef, useState } from "react";
import moment from "moment-timezone";

import { capitalizeFirstLetter } from "../../../common/helpers";
import Search, { defaultSearchValues, SearchHandle, SearchMeta } from "../../../common/search/search";
import ReloadButton from "../../../common/button/reload";
import useWindowSize from "../../../common/hooks/useWindowSize";
import TypeControl from "./type";
import DateControl from "./date";

import styles from "./index.module.css"

const TableControls = ({
  title,
  reload,
  filterButtonDate,
  setFilterButtonDate,
  dateOption,
  setDateOption,
  setCurrentSearch,
  experienceType,
  setExperienceType,
  setPage,
  setCount
}: {
  title: string | ReactNode;
  reload: (date?: Date) => void;
  filterButtonDate: Date;
  dateOption?: string,
  setDateOption?: (option: string) => void,
  setFilterButtonDate: (m: Date) => void;
  setCurrentSearch: (s: SearchMeta) => void;
  experienceType?: string;
  setExperienceType?: (type: string) => void;
  setPage: (n: number) => void;
  setCount: (n: number) => void;
}) => {
  const calendarDateFormat = "ddd MMM Do YYYY";
  const searchRef = useRef<SearchHandle>();
  const [width] = useWindowSize();

  const [filterButtonText, setFilterButtonText] = useState<string>(capitalizeFirstLetter(moment().format(calendarDateFormat)));
  
  const onSearchChange = (search: SearchMeta) => {
    localStorage.setItem('currentSearch', JSON.stringify(search));
    setCurrentSearch(search);
  }

  const resetSearch = () => {
    searchRef.current?.clear();
    setPage(1);
    setCount(0);
  }
  
  const resetReservationFilter = () => {
    resetSearch()
    // Reset day date
    setFilterButtonDate(new Date());
    setFilterButtonText(moment().format(calendarDateFormat));
    localStorage.setItem('currentSearch', "");
    setCurrentSearch(defaultSearchValues);
  }

  const changeDate = (isPast: boolean) => {
    resetSearch()
    const momentDate = moment(filterButtonDate);
    const date = isPast ? momentDate.subtract(1, "day") : momentDate.add(1, "day");
    setFilterButtonDate(date.toDate());
    setFilterButtonText(date.format(calendarDateFormat));
  }

  const changeCalendar = (val: Date) => {
    resetSearch()
    const momentDate = moment(val);
    setFilterButtonDate(momentDate.toDate());
    setFilterButtonText(momentDate.format(calendarDateFormat));
  }

  const SearchContainer = (
    <div className={styles.search}>
      <Search
        ref={searchRef}
        onSearchChange={onSearchChange}
      />
    </div>
  )

  return (
    <div className={styles["table-control"]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
        <div className={styles["table-control-section"]} style={{ gap: '15px' }}>
          {title}
        </div>
        {width <= 660 &&
        <>{SearchContainer}</>
        }
      </div>
      <div className={width > 660 ? `${styles["table-control-section"]} ${styles["controls-container"]}` : styles["controls-container"]}>
        {width > 660 &&
        <>{SearchContainer}</>
        }
        <div className={styles.controls}>
          <ReloadButton callback={() => reload(filterButtonDate)} style={{ marginRight: '15px' }} />
          <DateControl
            filterButtonDate={filterButtonDate}
            filterButtonText={filterButtonText}
            dateOption={dateOption}
            changeDateOption={setDateOption}
            changeDate={changeDate}
            changeCalendar={changeCalendar}
            resetReservationFilter={resetReservationFilter}
          />
        </div>
        {width > 660 && experienceType && setExperienceType &&
        <TypeControl
          value={experienceType}
          handleChange={setExperienceType}
        />
        }
      </div>
      {width < 660 && experienceType && setExperienceType &&
      <TypeControl
        value={experienceType}
        handleChange={setExperienceType}
      />
      }
    </div>
  )
}

export default TableControls;