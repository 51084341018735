import ReservationWaiverMapping from "./reservationWaiverMapping";

export enum E_WAIVER_TYPE {
  "NONE" = "none",
  "ADULT" = "adult",
  "CHILDREN" = "children",
  "COMBINED" = "combined"
}

export interface WaiverCustomer {
  firstname: string,
  lastname: string,
  email: string,
  phone: string,
  dob: string,
  isNewsletterSubscribed: boolean
  instagram: string,
  tiktok: string
}

export interface WaiverBlob {
  children: WaiverCustomer[],
  adult: WaiverCustomer | undefined,
  type: E_WAIVER_TYPE
}

export interface Waiver {
  waiver_id: number;
  uuid: string;
  email: string;
  blob: WaiverBlob;
  location: string;
  reservation_waiver_mapping: ReservationWaiverMapping;
}