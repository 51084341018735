import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tag from "../../../types/v2/tag"
import { faRemove } from "@fortawesome/free-solid-svg-icons"

const TagBadge = ({
  tag,
  select,
  remove
}: {
  tag: Tag,
  select?: (tag: Tag) => void,
  remove?: (tag: Tag) => void
}) => {
  return (
    <span
      style={{
        display: 'inline-block',
        backgroundColor: `${tag.color}`,
        padding: '4px 7px',
        borderRadius: '5px',
        width: 'max-content',
        maxWidth: 'fit-content',
        ...select ? { cursor: 'pointer' } : {}
      }}
      className="me-2 mb-2"
      onClick={() => select ? select(tag) : undefined}
    >
      {tag.name}
      {remove &&
      <FontAwesomeIcon
        icon={faRemove}
        className="ms-2"
        style={{
          cursor: 'pointer'
        }}
        onClick={() => remove(tag)}
      />
      }
    </span>
  )
}

export default TagBadge