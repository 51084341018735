const URI = process.env.REACT_APP_API_URL;
// const URI = 'api-tidalforcevr.loca.lt';
const HTTP_TRANSPORT = process.env.REACT_APP_IS_SECURED === 'false' ? 'http' : 'https';
const WS_TRANSPORT = process.env.REACT_APP_IS_SECURED === 'false' ? 'ws' : 'wss';
export const ROOT_URL = process.env.REACT_APP_API_ROOT || `${HTTP_TRANSPORT}://${URI}`;
export const WS_URL = process.env.REACT_APP_WEBSOCKET_ROOT || `${WS_TRANSPORT}://${URI}`;
export const JWT = process.env.REACT_APP_JWT || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiZjUwYTgxZDUtMTZiNy00YTIxLWFjNzktZmIwZjJkMTcyYTMyIiwiYXBwbGljYXRpb24iOiJSZXNlcnZhdGlvbkFkbWluaXN0cmF0aW9uIiwidGltZXpvbmUiOiJBbWVyaWNhL05ld19Zb3JrIiwiaWF0IjoxNTE2MjM5MDIyfQ.__EtMpcjhzivstnLvqHzkD066NboeA0jh5PfcOsVHYQ';
export const PUBLIC_BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL || 'https://tidalforcevr.com';
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'dev-hhgttd1y.us.auth0.com';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || 'uPPcnn7wMJAPKtWYV9fxEvbu128iR5f2';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://prod-api.tidalforcevraws.net';

export const MOMENT_FORMAT: string = 'MMM Do YY, ha';

export const RESERVATION = {
  ALL: 'all',
  FUTURE: 'future',
  TODAY: 'today',
  DAY: 'day',
}
