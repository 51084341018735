import { Alert } from "react-bootstrap";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  Legend
} from 'chart.js';
import { Bar } from "react-chartjs-2";

import Loader from "../../../common/loader/loader";
import Stat from "../../../common/stat";
import useTimestats from "..";

ChartJS.register([BarElement, Tooltip, CategoryScale, LinearScale, Legend]);

const TimeslotByHour = () => {
  const {
    timeStats,
    heatmapStats,
    isLoading,
    error
  } = useTimestats();

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {timeStats &&
        <Stat
          title="Timeslot usage by hours"
        >
          <Bar
            data={{
              labels: timeStats.map((stat: any) => stat.slot),
              datasets: [
                {
                  label: 'Timeslot usage by hour',
                  data: timeStats.map((stat: any) => stat.count),
                  backgroundColor: [
                    '#6987C9',
                  ]
                }
              ]
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#FFFFFF'
                  }
                }
              },
              borderColor: '#3F4045',
            }}
          />
        </Stat>
        }
      </Loader>
    </>
  )
}

export default TimeslotByHour;