import { useEffect, useState } from "react";
import moment from "moment";

import ApiService from "../../../services/api/v2";

interface Stat {
  count: number,
  slot: string
}

const useTimestats = () => {
  const [timeStats, setTimeStats] = useState<any>(undefined);
  const [heatmapStats, setHeatmapStats] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);

    ApiService.getTimeslotHour()
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats: Stat[]) => {
        setIsLoading(false);
        const formattedTimeStats: Stat[] = [];
        const formattedHeatmapStats: {
          [day:string]: Stat[]
        } = {};
        const timestatIndexes: { [key:string]: number } = {};
        const heatmapStatIndexes: {
          [day:string]: {
            [key:string]: number 
          }
        } = {};

        stats.forEach((stat: Stat) => {
          const day = moment(stat.slot).format('ddd');
          const slot = moment(stat.slot).format('ha');

          if (heatmapStatIndexes[day] !== undefined && heatmapStatIndexes[day][slot] !== undefined) {
            const s = formattedHeatmapStats[day][heatmapStatIndexes[day][slot]];
            s.count = s.count + stat.count;
            formattedHeatmapStats[day][heatmapStatIndexes[day][slot]] = s;
          } else if (heatmapStatIndexes[day] !== undefined && heatmapStatIndexes[day][slot] === undefined) {
            heatmapStatIndexes[day][slot] = formattedHeatmapStats[day].length;
            formattedHeatmapStats[day].push({ slot, count: stat.count })
          } else {
            formattedHeatmapStats[day] = [{ slot, count: stat.count }];
            heatmapStatIndexes[day] = { [slot]: 0 };
          }

          if (timestatIndexes[slot] !== undefined) {
            const s = formattedTimeStats[timestatIndexes[slot]];
            s.count = s.count + stat.count;
            formattedTimeStats[timestatIndexes[slot]] = s;
          } else {
            timestatIndexes[slot] = formattedTimeStats.length;
            formattedTimeStats.push({ slot, count: stat.count });
          }
        });

        formattedTimeStats.sort((a: any, b: any) => moment(a.slot, "h:mm a").unix() - moment(b.slot, "h:mm a").unix());

        setHeatmapStats(formattedHeatmapStats);
        setTimeStats(formattedTimeStats);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setTimeStats, setHeatmapStats, setIsLoading, setError]);

  return {
    timeStats,
    heatmapStats,
    isLoading,
    error
  }
}

export default useTimestats