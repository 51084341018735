import { useState } from "react";
import { Form } from "react-bootstrap";

const DateSelect = ({
  selected,
  handleChange
}: {
  selected: string,
  handleChange: (value: string) => void
}) => {
  const [value, setValue] = useState<string>("all");

  const onChange = (e: any) => {
    handleChange(e.target.value);
  }

  return (
    <Form.Select
      value={selected}
      onChange={(e: any) => handleChange(e.target.value)}
      className={`btn-primary`}
      style={{
        width: '75px',
        marginLeft: '15px',
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E")`
      }}
    >
      <option value="all">All</option>
      <option value="day">Day</option>
    </Form.Select>
  )
}

export default DateSelect;