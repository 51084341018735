import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import Select, { ActionMeta, MultiValue } from "react-select";
import { 
  Chart as ChartJS,
  PointElement,
  LineElement
} from "chart.js";

import ApiService from "../../../services/api/v2";
import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";

ChartJS.register([PointElement, LineElement]);

interface Option { value: string, label: string };
interface MCRStat { player_count: number, duration: number, slot: string };

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const MonthlyCustomerReservationCount = () => {
  const [stats, setStats] = useState<any>(undefined);
  const [customers, setCustomers] = useState<any>(undefined);
  const [reservations, setReservations] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<Option[] | undefined>(undefined);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (!stats) return;

    const cust: number[] = [];
    const res: number[] = [];
    const struct: any = {}

    stats.mcr.forEach((mcr: MCRStat) => {
      const year = moment(mcr.slot).year();
      const month = moment(mcr.slot).month();
      if (!struct[year]) {
        struct[year] = {};
        struct[year][month] = { customers: mcr.player_count * mcr.duration, reservations: 1 };
      } else {
        if (!struct[year][month]) {
          struct[year][month] = { customers: mcr.player_count * mcr.duration, reservations: 1 };
        } else {
          struct[year][month].customers += mcr.player_count * mcr.duration;
          struct[year][month].reservations += 1;
        }
      }
    });

    labels.forEach((label: string, index: number) => {
      const date = moment(label);
      const year = date.year();
      const month = date.month();
      cust.push(struct[year] && struct[year][month] ? struct[year][month].customers : 0);
      res.push(struct[year] && struct[year][month] ? struct[year][month].reservations : 0);
    });

    setCustomers(cust);
    setReservations(res);
  }, [stats])

  useEffect(() => {
    setError(undefined);
    if (moment().year().toString() === null) return;
    ApiService.getMonthlyCustomerReservationStats([moment().year().toString()])
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats: { mcr: MCRStat[], bounds: { min: string, max: string }}) => {
        const years = [];
        for (let i = moment(stats.bounds.min).year(); i <= moment(stats.bounds.max).year(); i++) {
          years.push({ value: i.toString(), label: i.toString() });
        }
        const selected = [years[years.length - 1]];
        setLabels(months.map((month: string) => `${month} ${selected[0].label}`));
        setOptions(years as Option[]);
        setSelectedOptions(selected);
        setStats(stats);
        setIsLoading(false);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setStats, setIsLoading, setError]);

  const handleSelectChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    const values = newValue as Option[];
    setSelectedOptions(values.sort((a: Option, b: Option) => a.value.localeCompare(b.value)));
    let lbls: string[] = [];
    values.forEach((opt: Option) => {
      lbls = lbls.concat(months.map((month: string) => `${month} ${opt.label}`));
    });
    setLabels(lbls);
  }

  useEffect(() => {
    ApiService.getMonthlyCustomerReservationStats(selectedOptions.map((option: Option) => option.value))
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats) => {
        setStats(stats);
        setIsLoading(false);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setStats, selectedOptions])

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {customers && reservations && options &&
        <Stat title={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Monthly Reservation & Customer</div>
            <Select
              options={options}
              isMulti
              value={selectedOptions}
              onChange={handleSelectChange}
              closeMenuOnSelect={false}
              styles={{
                option: (baseStyles) => ({
                  ...baseStyles,
                  color: 'black'
                })
              }}
            />
          </div>
        )}>
          <Bar
            data={{
              labels,
              datasets: [
                {
                  label: "Reservations",
                  data: reservations,
                  borderColor: '#88B380',
                  backgroundColor: '#88B380'
                },
                {
                  label: "Customers",
                  data: customers,
                  borderColor: '#6987C9',
                  backgroundColor: '#6987C9'
                }
              ]
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#FFFFFF'
                  }
                }
              },
              borderColor: '#3F4045'
            }}
          />
        </Stat>
        }
      </Loader>
    </>
  )
}

export default MonthlyCustomerReservationCount;