import { Button } from "react-bootstrap";
import { Reservation } from "../../../types/v2/reservation";

const TagButton = ({
  reservation,
  setReservation,
  setShowCanvas
}: {
  reservation: Reservation,
  setReservation: (r: Reservation) => void,
  setShowCanvas: () => void
}) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    setReservation(reservation);
    setShowCanvas();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'end'}}>
      <Button
        id={`addTag${reservation.reservation_id}`}
        onClick={onClick}
      >
        +
      </Button>
    </div>
  )
}

export default TagButton;